<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          Page <v-spacer></v-spacer>
          <!-- <v-btn
            color="primary"
            @click="newpage"
          >
            เพิ่ม Page
          </v-btn> -->
          <v-select
            v-model="currenttype"
            :items="pagetypes"
            item-text="label"
            item-value="value"
            hide-details
            dense
            outlined
          ></v-select>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="12">
            <v-text-field
              v-model="currentpage.title"
              label="Title* (ไม่ควรเกิน 60 ตัวอักษร)"
              counter
              dense
              outlined
              :rules="[v=> !!v || 'Required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="currentpage.description"
              label="Description* (ไม่ควรเกิน 120 ตัวอักษร)"
              counter
              dense
              outlined
              :rules="[v=> !!v || 'Required']"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-combobox
              v-model="currentpage.keywords"
              multiple
              chips
              deletable-chips
              label="Keywords"
              hide-details
              outlined
            ></v-combobox>
          </v-col>
          <v-col cols="12">
            <editor
              v-model="currentpage.content"
              api-key="meyc5j7whq9ffae2lbneuptktk8r1nielyhx17lisx11exc2"
              :init="{
                plugins: ['wordcount','link', 'image','lists'],
                file_picker_types: 'image',
                file_picker_callback: updateimg,
                height: 800,
                toolbar: 'undo redo | styleselect | bold italic | link image | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
              }"
            />
          </v-col>
          <v-col cols="12">
            <v-btn
              color="success"
              @click="savepage"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="snackbar"
      color="success"
      top
    >
      Save หน้า {{ currenttype }} เรียบร้อยแล้ว

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify,
  mdiPlusBox,
  mdiMinusBox,
} from '@mdi/js'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: { editor: Editor },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
        mdiPlusBox,
        mdiMinusBox,
      },
    }
  },
  data() {
    return {
      covertmp: null,
      thumbnailtmp: null,
      search: '',
      isloading: false,
      snackbar: false,
      findcontenttitle: '',
      currenttype: 'promotion',
      pagetypes: [
        { label: 'โปรโมชัน', value: 'promotion' },
      ],
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Type', align: 'start', value: 'type' },
        { text: 'Target', align: 'start', value: 'content' },
        { text: 'HTTPS', align: 'start', value: 'proxied' },
        { text: 'Action', value: 'action', width: '100' },
      ],
      contentgroups: [],
      contentgroup: '',
      mainpage: {

      },
      pages: [],
      dialogmode: 'new',
      currentpage: {
        title: '',
        description: '',
        type: 'promotion',
        content: '',
        keywords: [],
      },
    }
  },
  watch: {
    async findcontenttitle(val) {
      if (this.contents.length > 0) return
      const { data: { contents } } = await this.axios.get('/admin/contents/title', {
        params: { title: val },
      })
      this.contents = contents
    },
  },
  async created() {
    const { data } = await this.axios.get('/admin/mainpage')
    this.mainpage = data.website

    this.getpages()
  },
  methods: {
    async updateimg(cb, value, meta) {
      const input = window.document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')

      input.addEventListener('change', async e => {
        const file = e.target.files[0]

        const image = new FormData()
        image.append('img', file)
        image.append('alt', file.name)
        const { data } = await this.axios.post('/admin/image', image)

        cb(`https://cms.deepcdn.net/${data.img.Key}`, { title: file.name })
      })

      input.click()
    },
    async savepage() {
      const { data } = await this.axios.post('/admin/page', this.currentpage)
      console.log(data)
      this.snackbar = true
    },
    async getpages() {
      this.isloading = true
      const { data } = await this.axios.get('/admin/page', { params: { type: this.currenttype } })
      if (data.page) {
        this.currentpage = data.page
      } else {
        this.currentpage = {
          title: '',
          description: '',
          type: this.currenttype,
          content: '',
          keywords: [],
        }
      }
      this.isloading = false
    },
    newpage() {
      this.dialog = true
    },
  },
}
</script>
