var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"mb-6",attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Page "),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.pagetypes,"item-text":"label","item-value":"value","hide-details":"","dense":"","outlined":""},model:{value:(_vm.currenttype),callback:function ($$v) {_vm.currenttype=$$v},expression:"currenttype"}})],1),_c('v-row',{staticClass:"ma-0 pb-1 px-2 align-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Title* (ไม่ควรเกิน 60 ตัวอักษร)","counter":"","dense":"","outlined":"","rules":[function (v){ return !!v || 'Required'; }]},model:{value:(_vm.currentpage.title),callback:function ($$v) {_vm.$set(_vm.currentpage, "title", $$v)},expression:"currentpage.title"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Description* (ไม่ควรเกิน 120 ตัวอักษร)","counter":"","dense":"","outlined":"","rules":[function (v){ return !!v || 'Required'; }]},model:{value:(_vm.currentpage.description),callback:function ($$v) {_vm.$set(_vm.currentpage, "description", $$v)},expression:"currentpage.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"multiple":"","chips":"","deletable-chips":"","label":"Keywords","hide-details":"","outlined":""},model:{value:(_vm.currentpage.keywords),callback:function ($$v) {_vm.$set(_vm.currentpage, "keywords", $$v)},expression:"currentpage.keywords"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('editor',{attrs:{"api-key":"meyc5j7whq9ffae2lbneuptktk8r1nielyhx17lisx11exc2","init":{
              plugins: ['wordcount','link', 'image','lists'],
              file_picker_types: 'image',
              file_picker_callback: _vm.updateimg,
              height: 800,
              toolbar: 'undo redo | styleselect | bold italic | link image | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent'
            }},model:{value:(_vm.currentpage.content),callback:function ($$v) {_vm.$set(_vm.currentpage, "content", $$v)},expression:"currentpage.content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.savepage}},[_vm._v(" Save ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":"success","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" Save หน้า "+_vm._s(_vm.currenttype)+" เรียบร้อยแล้ว ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }